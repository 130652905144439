@font-face {
    font-family: 'icomoon';
    src: url('./fonts/icomoon.eot?tgqxlx');
    src: url('./fonts/icomoon.eot?tgqxlx#iefix') format('embedded-opentype'), url('./fonts/icomoon.ttf?tgqxlx') format('truetype'), url('./fonts/icomoon.woff?tgqxlx') format('woff'), url('./fonts/icomoon.svg?tgqxlx#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    /* speak: never; */
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-chargeback:before {
    content: "\e93a";
}

.icon-dashboard:before {
    content: "\e948";
}

.icon-deposit:before {
    content: "\e949";
}

.icon-earnings:before {
    content: "\e951";
}

.icon-faq:before {
    content: "\e952";
}

.icon-paymen:before {
    content: "\e953";
}

.icon-refund:before {
    content: "\e954";
}

.icon-reports:before {
    content: "\e959";
}

.icon-settings:before {
    content: "\e95a";
}

.icon-tools:before {
    content: "\e95b";
}

.icon-transaction:before {
    content: "\e95c";
}

.icon-Transactions-amount:before {
    content: "\e962";
}

.icon-withdrawl:before {
    content: "\e97e";
}

.icon-circle-plus:before {
    content: "\e922";
}

.icon-close:before {
    content: "\e91f";
}

.icon-plus:before {
    content: "\e913";
}

.icon-calendar:before {
    content: "\e914";
}

.icon-dots:before {
    content: "\e915";
}

.icon-delete:before {
    content: "\e918";
}

.icon-right-arrow:before {
    content: "\e91d";
}

.icon-user1:before {
    content: "\e90b";
}

.icon-buildings:before {
    content: "\e90c";
}

.icon-company:before {
    content: "\e90d";
}

.icon-mandi:before {
    content: "\e90e";
}

.icon-type-email:before {
    content: "\e90f";
}

.icon-lock:before {
    content: "\e910";
}

.icon-phone:before {
    content: "\e900";
}

.icon-email:before {
    content: "\e901";
}

.icon-language:before {
    content: "\e907";
}

.icon-icon-left:before {
    content: "\e908";
}

.icon-icon-right:before {
    content: "\e911";
}

.icon-icon-down:before {
    content: "\e912";
}