.main-wrapper {
    overflow: hidden;
}

.login-bg {
    /* background-image: url(https://dreamslms-wp.dreamguystech.com/wp-content/themes/dreamslms/assets/images/login-bg.png); */
    background-size: cover;
    top: 0;
    left: 0;
    position: fixed;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(50% 50% at 50% 50%, rgb(18, 38, 71) 0%, rgb(9, 14, 35) 100%);
}

.welcome-login {
    padding: 20px 20px 0px 20px;
}

.login-banner {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    background: #fff;
    margin: 0 auto;
    border-radius: 25px;
    overflow: hidden;
}

.login-banner img {
    max-width: 200px;
}

.mentor-course h2 {
    font-size: 30px;
    margin: 40px 0 20px;
    color: #fff;
}

.mentor-course p {
    margin-bottom: 0;
    max-width: 570px;
    margin: auto;
    color: #fff;
}

.login-wrap-bg {
    position: absolute;
    right: 0;
    padding: 0;
    background-color: #fff;
}

.merchant-onbording-btn a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.merchant-onbording-btn i {
    background-color: #1489d0;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    margin-right: 10px;
}

.login-wrapper {
    width: 100%;
    height: 100vh;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    background-color: #fff;
}

.login-wrapper .loginbox {
    max-width: 100%;
    width: 100%;
    padding: 90px 100px;
}

.login-wrapper .loginbox .img-logo {
    margin-bottom: 54px;
    position: absolute;
    right: 15px;
    top: 15px;
}

.login-wrapper .loginbox h1 {
    font-size: 30px;
    margin-bottom: 30px;
}

.login-wrapper .loginbox .img-logo img {
    width: 150px;
}

.back-home {
    margin-left: auto;
}

.back-home a {
    font-size: 18px;
    color: #1489d0;
    font-weight: 600;
}

.onbording-form-sec .error-message {
    position: static;
    color: rgb(228, 29, 29);
    padding: 0;
    font-size: 15px;
}

.onbording-form-sec .onbording-input {
    position: relative;
    margin-bottom: 15px;
}

.onbording-form-sec .onbording-input .form-label {
    position: static;
    color: #000;
    font-size: 15px;
    margin-bottom: 6px;
    display: block;
    padding: 0;
}

.onbording-form-sec .onbording-input .form-control {
    border: 1px solid #dedede;
    border-radius: 2px;
    height: calc(60px - 15px);
    padding: 0.35rem 0.98rem;
    margin-left: 0;
    font-size: 15px;
    font-weight: 400;
}

.input-field-validate {
    display: flex;
    align-items: center;
}

.input-group-onbording-otp p {
    position: static;
    color: #000;
    font-size: 15px;
    margin-bottom: 6px;
    display: block;
    font-weight: 600;
}

.input-field-validate .login-btn-sec {
    margin-left: 10px;
}

.input-group-onbording .login-btn-sec .btn {
    height: 45px;
    font-size: 16px;
    text-transform: capitalize;
    border-radius: 2px;
}

.login-right .form-control {
    border: 0;
    padding: 0;
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
}

.login-right .form-control::placeholder {
    color: rgba(13, 34, 89, 0.25);
}

.login-right .form-control:hover,
.login-right .form-control:focus {
    box-shadow: none;
}

.login-right .form-group {
    padding: 20px 20px 20px;
    border: 2px solid rgba(13, 34, 89, 0.1);
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 35px;
}

.login-right .form-group .icon {
    color: #c2c8d5;
    font-size: 25px;
}

.login-right .form-label {
    margin: 0;
    position: absolute;
    top: -14px;
    background: #fff;
    padding: 0 8px;
    font-weight: 600;
    font-size: 18px;
    color: rgba(55, 75, 92, 0.5);
}

.login-right .form-check-label {
    color: rgba(55, 75, 92, 0.75);
}

.login-right .form-check-input:checked {
    background-color: #66a154;
    border-color: #66a154;
}

.login-right .form-check .form-check-input {
    margin-left: -21px !important;
    margin-right: 8px;
}

.login-right .form-check-input[type="checkbox"] {
    border-radius: 5px;
    font-size: 20px;
}

.login-right select.form-select.form-control {
    color: rgba(13, 34, 89, 0.25);
}

.login-right-head .title {
    font-size: 55px;
    font-weight: bold;
    position: relative;
    display: inline-block;
}

.input-group-onbording {
    margin-bottom: 15px;
}

.login-right-head .title::after {
    content: "";
    height: 19px;
    width: 19px;
    background: #076df9;
    border-radius: 50px;
    position: absolute;
    right: -20px;
}

.btn {
    font-size: 18px !important;
    font-weight: 500 !important;
    height: 50px;
    min-width: 150px;
    letter-spacing: .063em;
    text-transform: uppercase;
}

.password-input {
    position: relative;
    width: 100%;
}

.password-input i {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.terms-of-policy a {
    color: #404040;
    font-weight: 600;
}

@media(max-width:1199.98px) {}

@media(max-width:1024.98px) {
    .login-wrapper .loginbox {
        padding: 40px 50px;
    }
}

@media(max-width:991.98px) {
    .login-wrapper .loginbox {
        padding: 30px 25px;
    }
}

@media(max-width:767.98px) {
    .loginbox {
        background-color: #fff;
        padding: 35px;
    }
    .login-wrapper .loginbox {
        padding: 50px 35px;
    }
    .login-bg {
        display: none;
    }
}