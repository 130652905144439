.formContainer_firstStage {
  font-family: Arial, sans-serif;
  width: 29rem;
  margin: 1rem auto;
  padding: 1rem;
  border: 1px solid lightgray;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1); */
  /* margin-left: 30rem; */
}
.viewButton_first_stage {
  padding: 2px;
  font-size: 12px;
  /* margin-top: 1rem; */
  /* float: right; */
  margin: 0.35rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.account_ifsc_verify {
  color: green;
  font-size: 12px;
  font-weight: 600;
  margin: 0.2rem;
}
.other_supporting_documents {
  display: none;
}
.viewButton_first_stage:hover {
  background-color: #0054ae;
  transform: scale(1.05);
  transition: background-color 0.2s ease, transform 0.2s ease;
}
.first_stage_parent {
  display: flex;
  align-items: center;
  /* justify-content: right; */
}
.popup_container_frist_stage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  /* background-color: rgb(202, 197, 197); */
}

.checkbox_first_stage {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.7rem;
  /* padding:rem; */
}
.get_verify_account {
  padding: 2px;
  font-size: 12px;
  /* margin-top: 1rem; */
  float: right;
  margin: 0.35rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.get_verify_account:hover {
  background-color: #0054ae;
  transform: scale(1.05);
  transition: background-color 0.2s ease, transform 0.2s ease;
}
.popup_first_stage {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 15rem;
}
.first_stage_first_child {
  margin-left: 3rem;
}
.formGroup {
  margin-bottom: 20px;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
}

.formGroup_first_page input[type="text"],
.formGroup_first_page input[type="file"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.formGroup button {
  margin-left: 5px;
  cursor: pointer;
  padding: 5px 8px;
  background-color: #ccc;
  border: none;
  border-radius: 50%;
  font-weight: bold;
}

.formGroup .error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
.error {
  color: red;
  font-size: 12px;
  font-weight: 500;
}
.first_stage_btn {
  padding: 10px 15px;
  margin-top: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.first_stage_btn:hover {
  background-color: #0056b3;
}

.addButton_first_stage {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-top: 0.3rem;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
