@import url('./typography.css');
body {
    font-family: 'Gilroy', sans-serif;
    font-weight: 400;
    font-size: 15px;
    background: #F0F0F7;
}

a {
    text-decoration: none;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

.shadow-cst {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06) !important;
}

.shadow {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06) !important;
}

.ds-header-card {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

td,
th {
    border-color: #a5c9ff;
}

th {
    font-weight: 600;
    font-size: 15px;
    text-wrap: nowrap;
    font-family: inter;
}

td {
    font-size: 14px;
    font-weight: 500;
    font-family: inter;
}

th:after {
    content: "\f124";
    font-family: bootstrap-icons!important;
    color: #065bd1;
    margin-left: 4px;
    font-size: 19px;
    position: relative;
    top: 4px;
}

tr:hover td {
    background-color: #f7faff;
    font-weight: 600;
}

tbody tr:hover {
    transform: scaleY(.95);
    box-shadow: 0 0.5rem 1rem rgb(150 187 248 / 47%), inset 0 -1px 0 rgb(250 250 250 / 79%);
}

.custom-table-style {
    border: 1px solid #a5c9ff;
}

.card {
    border-radius: 10px;
}
 

@media (min-width: 1500px) {
    .container {
        max-width: 90%;
    }
}