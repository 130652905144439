/* Header Style Here */

.lincpay-header-sec {
    background-color: #000;
}

.navbar-brand {
    width: 70px;
    background-color: #fff;
    border-radius: 100px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.navbar-brand img {
    max-width: 60px;
}

.navbar-nav .nav-item a {
    font-weight: 400;
    font-size: 18px;
    padding: 6px 15px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f0f0f0;
}

.navbar-nav .nav-item em {
    margin-right: 6px;
}

.sign-out i {
    margin-right: 0 !important;
    margin-left: 6px;
}

.merchant-name {
    font-weight: 600;
    letter-spacing: .06em;
    font-size: 18px;
    color: #f0f0f0;
}

.dropdown-toggle::after {
    color: #fff;
}

.navbar-nav .nav-item.active,
a .navbar-nav .nav-item.show a {
    color: #fff;
    background-color: #065bd1;
    border-radius: 100px;
}

.navbar-nav .nav-item:not(:last-child) {
    margin-right: 25px;
}

.user__img {
    max-width: 50px;
    overflow: hidden;
    background: #fff;
    border: 2px solid #065bd1;
    margin-left: 10px;
}

.lincpay-header-sec .navbar-toggler {
    background-color: #fff;
    border: 1px solid #dedede;
}

@media(max-width:1680.98px) {
    .navbar-nav .nav-link {
        font-size: 16px;
        padding: 5px 12px !important;
    }
}

@media(max-width:1540.98px) {
    .navbar-nav .nav-link {
        font-size: 15px;
        padding: 5px 10px !important;
    }
}

@media(max-width:1399.98px) {
    .navbar-nav {
        margin-top: 15px !important;
    }
    .navbar-nav .nav-link {
        justify-content: start;
    }
    .navbar-nav .nav-link:not(:last-child) {
        margin-bottom: 10px;
    }
}