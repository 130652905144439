#city {
  max-width: 10rem;
}
.adharform_container {
  max-width: 40rem;
  margin: 1rem auto;
  padding: 1.5rem;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1); */
}

/* Hide the increase and decrease arrows for number input */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the increase and decrease arrows for number input in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.main_logo {
  height: 2.5rem;
  position: relative;
  bottom: 1rem;
  right: 1.1rem;
}
.aadhar_verify_msg {
  color: red;
  font-size: small;
  font-weight: 500;
}
.skip_aadhar_verification_button {
  background-color: rgb(171, 23, 23);
  color: white;
  padding: 0.2rem 0.6rem;
  font-size: 0.7rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  max-width: 5rem;
  /* margin-top: rem; */
  position: relative;
  bottom: 0.5rem;
  right: 1rem;
  float: right;
}
.adharform_field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.close_button {
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
#aadharForm2 {
  width: 16rem;
  padding: 0.5rem;
  font-size: 0.75em;
  border: 1px solid #ccc;
  border-radius: 10px;
}
#aadharForm3 {
  width: 37rem;
  padding: 0.5rem;
  font-size: 0.75em;
  border: 1px solid #ccc;
  border-radius: 10px;
}

#aadharForm {
  width: 100%;
  padding: 0.5rem;
  font-size: 0.75em;
  border: 1px solid #ccc;
  border-radius: 10px;
}

/* input {
  width: 100%;
  padding: 0.5rem;
  font-size: 0.75em;
  border: 1px solid #ccc;
  border-radius: 10px;
} */

.error {
  border-color: red;
}

.error_message {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.25rem;
}

.adhar_send_otp_button {
  background-color: #4caf50;
  color: white;
  /* padding: 0.5rem 0.5rem; */
  font-size: 0.8rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  max-width: 5rem;
  /* margin-top: rem; */
  position: relative;
  bottom: 0.5rem;
  /* left: 22rem; */
  float: right;
}
.adhar_send_otp_button:hover {
  background-color: green;
  transform: scale(1.05);
  transition: background-color 0.2s ease, transform 0.2s ease;
}
.popup_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 15rem;
}
.popup_success {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 15rem;
}
.popup_container_success {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.popup h3 {
  color: rgb(239, 86, 86);
  font-weight: 600;
  font-size: large;
}
.verify_otp_btn {
  background-color: #4caf50;
  color: white;
  /* padding: 0.5rem 0.5rem; */
  font-size: 0.8rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  max-width: 5rem;
  /* margin-top: rem; */
  position: relative;
  bottom: 0.7rem;
  /* left: 22rem; */
  float: right;
}
.verify_otp_btn:hover {
  background-color: green;
  transform: scale(1.05);
  transition: background-color 0.2s ease, transform 0.2s ease;
}
.verify_otp_btn_pan {
  background-color: #4caf50;
  color: white;
  /* padding: 0.5rem 0.5rem; */
  font-size: 0.8rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  max-width: 5rem;
  /* margin-top: rem; */
  position: relative;
  top: 0.4rem;
  left: 31.9rem;
  /* float: right; */
}
.verify_otp_btn_pan:hover {
  background-color: green;
  transform: scale(1.05);
  transition: background-color 0.2s ease, transform 0.2s ease;
}
.popup_success h3 {
  color: green;
  font-weight: 600;
  font-size: large;
}
.success_otp {
  color: green;
  font-weight: 500;
  margin-top: 2px;
  font-size: 14px;
}
.submit_button {
  background-color: #4caf50;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  /* margin-top: 1rem; */
}

.pin_state {
  display: flex;
  gap: 5rem;
}
