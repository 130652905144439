.btn-inherit-p {
    border: 0;
    background-color: transparent;
    padding: 0;
    outline: 0;
    box-shadow: none;
    display: block;
    width: 100%;
    height: 160px;
}

.dashboard-boxs {
    border-radius: 20px;
}

.ds-icon {
    filter: grayscale(100%);
}

.ds-card-icon {
    position: absolute;
}

.ds-card-dt {
    text-align: center;
    margin-top: 25px;
}

.ds-label-name {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    min-height: 40px;
}

.ds-count-value {
    font-size: 35px;
}

.dashboard-boxs:hover .ds-count-value,
.dashboard-boxs:hover .ds-label-name,
.dashboard-boxs:hover .ds-icon {
    color: #065bd1;
    filter: grayscale(0);
}

.card-chart-box .recharts-wrapper,
.card-chart-box .recharts-wrapper svg {
    width: 100% !important;
}

@media(min-width:1440px) {}