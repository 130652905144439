.company-form-container {
    max-width: 40rem;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1); */
    margin: 1rem auto;
    padding: 1.5rem;
}

.main_logo_company {
    height: 2.5rem;
    position: relative;
    bottom: 1rem;
    right: 1.1rem;
}


/* Assuming you have a CSS file (e.g., CompanyAddForm.css) where you define the styles */

.checkbox-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: 3.5rem;
    padding-right: 0.7rem;
}

.horizontal-input-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.horizontal-input-container+.horizontal-input-container {
    margin-left: 15px;
}

.company_city_dist {
    display: flex;
    gap: 8rem;
}

.company_state_dist {
    display: flex;
    gap: 12rem;
}

.company-form-row {
    margin-bottom: 10px;
}

.toggle-switch1 {
    /* margin-right: 20rem; */
    display: flex;
    gap: 2rem;
    align-items: center;
}

.toggle-switch1 label {
    /* margin-right: 20rem; */
    font-size: 12px;
    font-weight: 700;
}

.company-pin-state {
    display: flex;
    gap: 5rem;
}

#companyForm2 {
    max-width: 16rem;
    /* background-color: #4caf50; */
}

#companyForm {
    width: 16rem;
}

.referal_label {
    margin: 0.2rem;
}

.company-city {
    display: block;
    max-width: 13rem;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="checkbox"] {
    margin-left: 5px;
}


/* input[type="text"],
select {
  width: 100%;
  padding: 0.5rem;
  font-size: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 10px;
} */

input[type="number"],
select {
    width: 100%;
    padding: 0.5rem;
    font-size: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.toggle-switch main-label {
    display: flex;
    gap: 0;
}

.submit-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.error {
    border-color: red;
}

.error-message {
    color: red;
    font-size: 0.8rem;
    margin-top: 0.25rem;
}

.success-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.success-popup {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 15rem;
}

.success-popup h3 {
    color: green;
    font-weight: 700;
}

.toggle-text {
    position: relative;
    float: right;
    bottom: 1.8rem;
}

.submit-btn:disabled {
    background-color: #4caf50;
    cursor: not-allowed;
}

.toggle-switch {
    position: relative;
    display: block;
    margin: 0.5rem;
    width: 40px;
    height: 20px;
}

.toggle-switch input[type="checkbox"] {
    display: none;
}

.toggle-switch label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ccc;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.toggle-switch label:before {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

.toggle-switch input[type="checkbox"]:checked+label {
    background-color: #4caf50;
}

.toggle-switch input[type="checkbox"]:checked+label:before {
    transform: translateX(20px);
}