

.check-box-decleration input[type="checkbox"], .checkbox-select-services input[type="checkbox"]{
    width: 25px;
    height: 25px;
    margin-left: 0;
    margin-right: 15px;
    margin-top: 0;
}

.check-box-decleration .form-label, .checkbox-select-services .form-label{
    margin-bottom: 0 !important;
    font-weight: 600 !important;
}

.checkservices-type {
    display: inline-flex;
    align-items: center;
}
.checkbox-select-services .checkservices-type input[type="checkbox"]{
    margin-right: 10px;
    width: 20px;
    height: 20px;
}
.checkbox-select-services .checkservices-type .form-label{
    margin-bottom: 0 !important;
    font-weight: 500 !important;
}

.checkbox-select-services .checkservices-type:not(:last-child){
    margin-right: 15px;
}

.tool-bar-btn .btn:not(:last-child){
    margin-right: 15px;
}
 