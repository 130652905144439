.chart-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  
  .pie-chart {
    margin-bottom: 20px;
  }
  
  .legend-container {
    text-align: center;
  }