.btn {
    text-transform: capitalize;
}


.merchant-form-info .form-group .form-control, .merchant-form-info .form-group .form-control:focus {
    border: 1px solid #dedede;
    border-radius: 2px;
    height: calc(60px - 15px);
    padding: 0.35rem 0.98rem;
    margin-left: 0;
    font-size: 15px;
    font-weight: 400;
    box-shadow: none;
    outline: 0;
}
.merchant-form-info .form-group .form-label{
    position: static;
    color: #000;
    font-size: 15px;
    margin-bottom: 6px;
    display: block;
    padding: 0;
    font-weight: 500;
}
.merchant-form-info .form-group{
    margin-bottom: 15px;
}
.merchant-info-form-sec .card-title {
    margin-bottom: 25px;
    border-bottom: 1px solid #3b91c5;
    padding-bottom: 10px;
    color: #4094c7;
}
.merchant-info-form-sec .input-field-validate .login-btn-sec .btn {
    border-radius: 2px;
    font-size: 16px !important;
    font-weight: normal;
}
.aadharform-sec .form-group .error_message{
    color: #ed4f4a;
    font-size: 15px;
}

.popup_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .popup {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 15rem;
  }
  .popup_success {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 15rem;
  }
  .popup_container_success {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .popup h3 {
    color: #4caf50;
    font-weight: 600;
    font-size: 18px;
  }
  .verify_otp_btn {
    background-color: #4caf50;
    color: white;
    /* padding: 0.5rem 0.5rem; */
    font-size: 0.8rem;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    max-width: 5rem;
    /* margin-top: rem; */
    position: relative;
    bottom: 0.7rem;
    /* left: 22rem; */
    float: right;
  }
  .verify_otp_btn:hover {
    background-color: green;
    transform: scale(1.05);
    transition: background-color 0.2s ease, transform 0.2s ease;
  }
  .verify_otp_btn_pan {
    background-color: #4caf50;
    color: white;
    /* padding: 0.5rem 0.5rem; */
    font-size: 0.8rem;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    max-width: 5rem;
    /* margin-top: rem; */
    position: relative;
    top: 0.4rem;
    left: 31.9rem;
    /* float: right; */
  }
  .verify_otp_btn_pan:hover {
    background-color: green;
    transform: scale(1.05);
    transition: background-color 0.2s ease, transform 0.2s ease;
  }
  .popup_success h3 {
    color: green;
    font-weight: 600;
    font-size: large;
  }
  .success_otp {
    color: green;
    font-weight: 500;
    margin-top: 2px;
    font-size: 14px;
  }
  .submit_button {
    background-color: #4caf50;
    color: white;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    /* margin-top: 1rem; */
  }
  .close_button {
    background-color: #4caf50;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }  