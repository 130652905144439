.merchant-tab-btn .nav-report-btn {
    display: flex;
    flex-wrap: wrap;
}

.merchant-tab-btn .nav-report-btn li {
    text-align: center;
    padding: 25px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    display: inline-flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
}

.merchant-tab-btn .nav-report-btn li:hover {
    background-color: #f0f0f0;
}

.merchant-tab-btn .nav-report-btn li:not(:last-child) {
    margin-right: 15px;
}

.merchant-tab-btn .nav-report-btn li.active {
    background-color: #000;
    color: #fff;
    box-shadow: 0 2px 6px rgb(0 0 0 / 61%) !important;
}

.custom-filter-box {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 15px;
}

.custom-filter-box>div:not(:last-child) {
    margin-right: 15px;
}

.search-records-view .custom-filter-box .btn {
    font-size: 15px !important;
    height: 46px;
    border-radius: 6px;
    min-width: 145px;
}

.search-records-view .custom-filter-box .btn-secondary {
    background-color: #f0f0f0;
    color: #333;
    border-color: #dedede;
}

.serch_field_text {
    width: 300px;
    position: relative;
}

.serch_field_text i {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    bottom: 0;
    width: 40px;
    justify-content: center;
    font-size: 15px;
    color: #ddd;
}

.serch_field_text .form-control,
.serch_field_text .form-control:focus {
    box-shadow: none;
    border-color: #dedede;
    border-radius: 6px;
    height: 46px;
    font-size: 15px;
    font-weight: 500;
    padding-left: 40px;
}

.serch_field_text .form-control::placeholder {
    color: #ddd;
}

.share-link-btn button {
    display: inline-flex;
    align-items: center;
    flex-direction: row-reverse;
    border: 1px solid #076df9 !important;
    height: 50px;
    padding: 10px !important;
    border-radius: 6px;
    box-shadow: 0 2px 6px rgb(33 150 243 / 14%) !important;
}

.share-link-btn .share_text_earning {
    margin-bottom: 0;
    font-size: 17px;
    margin-left: 10px;
    color: #076df9;
    font-weight: 500;
}

.referral_share_parent {
    display: flex;
    align-items: center;
}

.share-link-btn:not(:last-child) {
    margin-right: 10px;
}

.referral_first_icon p {
    margin: 0;
    margin-left: 10px;
}

.referral_first_icon {
    display: flex;
    align-items: center;
}

.referral_code {
    margin: 0;
}

.referral_code+.referral-cd-value {
    background-color: #333;
    color: #fff;
    font-size: 19px;
    padding: 6px 12px;
    border-radius: 6px;
    display: inline-block;
}

.Referral_earn p {
    margin-bottom: 0;
}

.referral_link_main,
.referral_code {
    font-size: 22px;
    margin-bottom: 10px !important;
    font-weight: 600;
}

.referral_first_icon {
    background-color: #ecf4ff;
    padding: 10px;
    border-radius: 6px;
}