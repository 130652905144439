/* OtpVerification.module.css */

.containerAdhaar {
  max-width: 96%;
  margin: 4rem auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  gap: 3rem;
  justify-content: stretch;
  /* align-items: flex-start; */
  /* min-height: 100vh; */
}
.image_div_aadhar {
  background-color: #242348;
  /* height: 100%;*/
  width: 40%;
}
.Aadhaar_second_child {
  /* margin-top: 7.5rem; */
}

.AdhaarVerificationLogo {
  /* height: 8rem; */
  /* margin-left: 1rem; */
  /* position: relative; */
  /* top: 15rem; */
}
.AdhaarVerification_link {
  text-align: center;
}

.labelAdhaar {
  display: block;
  margin-bottom: 10px;
}

.inputAdhaar {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.buttonAdhaarVerification {
  display: block;
  width: 6rem;
  padding: 6px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.buttonAdhaarVerification:hover {
  background-color: #0056b3;
  color: #f0f0f0;
}

.resend_otp_adhar {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  float: right;
  padding: 2px;
  font-size: 12px;
}
.resend_otp_adhar:hover {
  background-color: #0056b3;
  color: #f0f0f0;
}
.result_adhaar {
  margin-top: 10px;
  text-align: center;
  font-weight: bold;
}
