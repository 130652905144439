.admin-view-details .vertical-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.admin-view-details .vertical-table-row {
    width: 32.33%;
    margin-bottom: 10px;
}

.admin-view-details .vertical-table-cell {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 4px;
}

.admin-view-details .vertical-table-cell-value {
    background-color: #dedede52;
    padding: 4px;
    min-height: 38px;
    display: flex;
    align-items: center;
    border-radius: 2px;
    font-size: 14px;
}

.vertical-table-cell-value .btn {
    height: auto;
    padding: 2px 5px;
    min-width: auto;
    font-size: 14px !important;
}

.payment-services-list {
    margin-left: 15px;
}

.payment-services-list .selected-service {
    font-weight: 600;
    font-size: 16px;
    color: #03A9F4;
}

.button-toolbar .btn {
    min-width: inherit;
    height: auto;
    font-size: 13px !important;
}

.groups-btn .btn:not(:first-child) {
    margin-left: 5px;
}

.message-box-btn {
    display: flex;
    align-items: center;
}

.message-box-btn .btn {
    margin-left: 10px;
}

@media(max-width:1199.98px) {
    .admin-view-details .vertical-table-row {
        width: 49.33%;
        margin-bottom: 10px;
    }
}

@media(max-width:991.98px) {
    .admin-view-details .vertical-table-row {
        width: 100%;
        margin-bottom: 10px;
    }
}