@font-face {
    font-family: 'Gilroy';
    src: url('./fonts/Gilroy-SemiBold.eot');
    src: url('./fonts/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'), url('./fonts/Gilroy-SemiBold.woff2') format('woff2'), url('./fonts/Gilroy-SemiBold.woff') format('woff'), url('./fonts/Gilroy-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('./fonts/Gilroy-Regular.eot');
    src: url('./fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'), url('./fonts/Gilroy-Regular.woff2') format('woff2'), url('./fonts/Gilroy-Regular.woff') format('woff'), url('./fonts/Gilroy-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('./fonts/Gilroy-UltraLight.eot');
    src: url('./fonts/Gilroy-UltraLight.eot?#iefix') format('embedded-opentype'), url('./fonts/Gilroy-UltraLight.woff2') format('woff2'), url('./fonts/Gilroy-UltraLight.woff') format('woff'), url('./fonts/Gilroy-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('./fonts/Gilroy-Medium.eot');
    src: url('./fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'), url('./fonts/Gilroy-Medium.woff2') format('woff2'), url('./fonts/Gilroy-Medium.woff') format('woff'), url('./fonts/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('./fonts/Gilroy-Bold.eot');
    src: url('./fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'), url('./fonts/Gilroy-Bold.woff2') format('woff2'), url('./fonts/Gilroy-Bold.woff') format('woff'), url('./fonts/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('./fonts/SegoeUI.eot');
    src: url('./fonts/SegoeUI.eot?#iefix') format('embedded-opentype'), url('./fonts/SegoeUI.woff2') format('woff2'), url('./fonts/SegoeUI.woff') format('woff'), url('./fonts/SegoeUI.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/SourceSansProRegular.eot');
    src: url('./fonts/SourceSansProRegular.eot?#iefix') format('embedded-opentype'), url('./fonts/SourceSansProRegular.woff2') format('woff2'), url('./fonts/SourceSansProRegular.woff') format('woff'), url('./fonts/SourceSansProRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

h1,
.h1 {
    font-weight: bold;
    font-size: 55px;
}

h2,
.h2 {
    font-weight: bold;
    font-size: 45px;
}

h3,
.h3 {
    font-weight: 600;
    font-size: 25px;
}


/* h4,
.h4 {}

h5,
.h5 {}

h6,
.h6 {} */

p,
a {
    font-size: 16px;
    font-weight: 500;
}

label {
    font-size: 18px;
}

.text-capitlize {
    text-transform: capitalize;
}