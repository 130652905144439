/* EmailForm.css */

.form-container-email {
  max-width: 95%;
  margin: 4.5rem auto;
}

.form-field-email {
  margin-bottom: 1rem;
  margin-left: 10rem;
}
.email-merchant-title {
  margin-left: 4.7rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.email-form-parent {
  margin-left: 2.5rem;
  /* border: 1px solid gray; */
}
/* .email-form-onboard {
} */
.email-form-child {
  margin-left: 5rem;
}
.Logo {
  height: 50%;
  width: 50%;
  margin-bottom: 1rem;
}

#email_form_input {
  width: 70%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 0.35rem;
}
.main-logo-email {
  height: 2.5rem;
  position: relative;
  bottom: 1rem;
  left: 17.5rem;
}
.error {
  border-color: red;
}
.swiplinc-logo {
  height: 15rem;
}

.error-message {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.25rem;
}

.send-otp-button-email-form {
  background-color: darkgreen;
  color: white;
  padding: 0.1rem 0.5rem;
  font-size: 0.7rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  /* margin-left: 1.3rem; */
  margin-bottom: 1rem;
  margin-left: 26.5rem;
  /* float: right; */
}
.send-otp-button-email-form:hover {
  background-color: green;
  transform: scale(1.05);
  transition: background-color 0.2s ease, transform 0.2s ease;
}
.resend-otp-button-email:hover {
  background-color: green;
  transform: scale(1.05);
  transition: background-color 0.2s ease, transform 0.2s ease;
}
.resend-otp-button-email {
  background-color: darkgreen;
  color: white;
  /* padding: 0.5rem 1rem; */
  font-size: 0.75rem;
  border: none;
  display: block;
  /* float: right; */
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  border-radius: 20px;
  cursor: pointer;
  margin-left: 0.5rem;
}

.send-otp-button {
  background-color: #4caf50;
}

.otp-field {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.otp-field input {
  margin-left: 0.5rem;
  flex-grow: 1;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 15rem;
}

.popup h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.popup p {
  font-size: 14px;
  margin-bottom: 20px;
}

.close-button {
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.otp_message {
  color: green;
  font-size: 14px;
  font-weight: 500;
}
.close-button:hover {
  background-color: #ff3333;
}
@media (min-width: 320px) and (max-width: 480px) {
  .form-container-email {
    position: relative;
    right: 14rem;
  }
  #email_form_input {
    max-width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 0.35rem;
  }
  .email-form-parent {
    margin-left: 14rem;
    /* height: 30rem; */
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .form-container-email {
    position: relative;
    right: 14rem;
  }
  .email-form-parent {
    margin-left: 14rem;
    /* height: 30rem; */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .form-container-email {
    position: relative;
    right: 14rem;
  }
  .email-form-parent {
    margin-left: 15rem;
  }
  .send-otp-button-email-form {
    background-color: darkgreen;
    color: white;
    padding: 0.1rem 0.5rem;
    font-size: 0.7rem;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    /* margin-left: 1.3rem; */
    margin-bottom: 1rem;
    margin-left: 17.2rem;
    /* float: right; */
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .form-container-email {
    position: relative;
    right: 14rem;
  }
  .email-form-parent {
    margin-left: 14rem;
  }

  .send-otp-button-email-form {
    background-color: darkgreen;
    color: white;
    padding: 0.1rem 0.5rem;
    font-size: 0.7rem;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    /* margin-left: 1.3rem; */
    margin-bottom: 1rem;
    margin-left: 23.2rem;
    /* float: right; */
  }
}
